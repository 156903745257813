import Bugsnag from '@bugsnag/js';

// NOTE: jsのシンタックスエラーも通知させる為にエントリーポイントを別にしている

// NOTE: テスト実行時の notifyReleaseStages 警告が邪魔だからログ無効化
const options = process.env.RAILS_ENV === 'test' ? { logger: null } : {};

Bugsnag.start({
  apiKey: '6fbeab1ad40b3b38b10e02bc86ba4300',
  releaseStage: process.env.RAILS_ENV,
  notifyReleaseStages: ['staging', 'production'],
  ...options,
});

// NOTE: Performanceモニターできるらしい
import BugsnagPerformance from '//d2wy8f7a9ursnm.cloudfront.net/v1/bugsnag-performance.min.js'
BugsnagPerformance.start({ apiKey: '6fbeab1ad40b3b38b10e02bc86ba4300' })
